import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
  @Output() fileSelected = new EventEmitter<File>();

  fileUploadForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.fileUploadForm = this.fb.group({
      file: [null, Validators.required]
    });
  }

  onFileChange(event) {
    const fileInput = event.target;
    const file = fileInput.files[0];
    if (file) {
      this.fileSelected.emit(file);
      this.fileUploadForm.patchValue({
        file: file
      });
      // Clear the file input value
      fileInput.value = '';
    }
  }
}
