import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [
  {
    path: '/maps',
    title: 'Map',
    icon: 'ft-home',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Dizionari',
    icon: 'ft-align-left',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/dictionaries/branches',
        title: 'Agenzie',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/dictionaries/cadastral-categories',
        title: 'Categorie Catastali',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/dictionaries/estate-ownership-types',
        title: 'Tipologie di proprietà',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/dictionaries/estate-statuses',
        title: 'Stati immobili',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/dictionaries/estate-types',
        title: 'Tipologie di immobili',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/users-manager',
    title: 'Utenti',
    icon: 'ft-user',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: []
  },
  {
    path: '/estate-export/excel',
    title: 'Export',
    icon: 'ft-download',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: []
  },
  {
    path: '/visengine-requests',
    title: 'Richiete e Visure',
    icon: 'ft-file-text',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: []
  }
];
