import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { CanDeactivateComponent } from './can-deactivate.component';
import { ConfirmCancelButton } from '../services/sweet-alerts';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
    canDeactivate(component: CanDeactivateComponent): Promise<boolean> {
        if (!component.canDeactivate()) {
            return ConfirmCancelButton(
                'Sono state effettuate delle mofiche senza salvare!<br> Cliccando su conferma, le modifche non salvate verranno perse',
            ).then(
                (result) => {
                    return !(result && result.dismiss);
                },
                // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
                (dismiss) => {
                    return false;
                },
            );
        }
        return Promise.resolve(true);
    }
}
