import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { UserView } from "../models/user.view";

const USER_VIEW = "uv";

@Injectable({
  providedIn: 'root',
})
export class MeService {
  private baseUrl: string;

  constructor(public readonly http: HttpClient) {
    this.baseUrl = `${environment.API_BASE_URL}/api/v1/users/current`;
  }

  getProfile(): Observable<UserView> {
    const userView = this.getUserView();
    if (!!userView) {
      of(userView);
    }
    return this.http.get<any>(this.baseUrl).pipe(
      map((response) => {
        if (response && response.data) {
          const view: UserView = {
            email: response.data.email,
            displayName: response.data.displayName,
            role: response.data.role,
          };
          this.saveUserView(view);
          return view;
        }
        return null;
      })
    );
  }

  public signOut() {
    sessionStorage.removeItem(USER_VIEW);
  }

  private saveUserView(userView: UserView) {
    sessionStorage.removeItem(USER_VIEW);
    if (userView) {
      sessionStorage.setItem(USER_VIEW, JSON.stringify(userView));
    }
  }

  private getUserView(): UserView {
    const str = sessionStorage.getItem(USER_VIEW);
    if (!str || str.length === 0) {
      return null;
    }
    return JSON.parse(str);
  }
}
