import { Injectable } from '@angular/core';
import { urlBase64Decode } from '../helpers';

import { Observable, Subject } from 'rxjs';

const TOKEN_KEY = 'AuthToken';

@Injectable()
export class TokenStorageService {
    private timer: any;
    private subject = new Subject<any>();

    constructor() {
        this.tokenWatcher();
    }

    public signOut() {
        sessionStorage.removeItem(TOKEN_KEY);
        sessionStorage.clear();
    }

    public saveToken(token: string) {
        sessionStorage.removeItem(TOKEN_KEY);
        if (token && token.length > 0) {
            sessionStorage.setItem(TOKEN_KEY, token);
            this.tokenWatcher();
        }
    }

    public getToken(): string {
        return sessionStorage.getItem(TOKEN_KEY);
    }

    /**
     * Returns payload object
     * @returns any
     */
    public getPayload(): any {
        const token = this.getToken();
        if (!token) {
            console.log(
                `The token ${token} is not valid JWT token and must consist of three parts.`,
            );
            return null;
        }
        const parts = token.split('.');

        if (parts.length !== 3) {
            throw new Error(
                `The token ${token} is not valid JWT token and must consist of three parts.`,
            );
        }

        const decoded = urlBase64Decode(parts[1]);
        if (!decoded) {
            throw new Error(`The token ${token} is not valid JWT token and cannot be decoded.`);
        }

        return JSON.parse(decoded);
    }

    /**
     * Returns expiration date
     * @returns Date
     */
    public getTokenExpDate(): Date {
        const date = new Date(0);
        try {
            const decoded = this.getPayload();
            if (!(decoded && decoded.hasOwnProperty('exp'))) {
                return null;
            }

            date.setUTCSeconds(decoded.exp);
        } catch (e) {
            console.log(`getTokenExpDate ${e}`);
        }
        return date;
    }

    public tokenExpNotification(): Observable<any> {
        return this.subject.asObservable();
    }

    private tokenWatcher(): void {
        try {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            const decoded = this.getPayload();
            if (!(decoded && decoded.hasOwnProperty('exp'))) {
                return null;
            }
            this.timer = setTimeout(() => {
                this.subject.next(1);
            }, Math.max(600000, decoded.exp * 1000 - Date.now() - 30000));
        } catch (e) {
            console.log(`tokenWatcher ${e}`);
        }
    }
}
