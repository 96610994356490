export enum UserRole {
  /**
   * @type {String}
   */
  ADMIN = 'admin',
  /**
   * @type {String}
   */
  USER = 'user',
}
